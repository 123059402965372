import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Post.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useSelector } from "react-redux";
import LikedBy from "./LikedBy";
import Moment from "react-moment";
import { openai } from "../../firebase";

import {
  collection,
  deleteDoc,
  addDoc,
  getDoc,
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  query,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from "react-router-dom";

function ActivityPost({
  isProfile,
  id,
  userId,
  user,
  profileImage,
  college,
  quote,

  isSocial,
  isAnonymous,

  isActivity,
  activity,
  advice,
  memory,
  significance,

  isAcademic,
  academicGoal,
  ib,
  course,
  value,
  teacher,
  relatedActs,

  isCollege,
  research,
  fairs,
  knownEarlier,
  selection,
  regrets,
  essayLink,
  resumeLink,
  essayApproach,

  isJob,
  employer,
  purpose,
}) {
  const [showDetails, setShowDetails] = useState(false);
  const [showLiked, setShowLiked] = useState(false);

  const [likes, setLikes] = useState([]);
  const [hasLiked, setHasLiked] = useState(false);

  const currentUser = useSelector((state) => state.data.user.user);

  useEffect(
    () =>
      onSnapshot(collection(db, "posts", id, "likes"), (snapshot) =>
        setLikes(snapshot.docs)
      ),
    [db, id]
  );

  useEffect(
    () =>
      setHasLiked(
        likes.findIndex((like) => like.id === currentUser?.uid) !== -1
      ),
    [likes]
  );

  const likePost = async () => {
    const postLikes = doc(db, "posts", id);
    const postLikesSnap = await getDoc(postLikes);
    const currentLikes = postLikesSnap?.data()?.likesCount || 0;

    if (hasLiked) {
      await deleteDoc(doc(db, "posts", id, "likes", currentUser.uid));

      await setDoc(
        postLikes,
        {
          likesCount: currentLikes - 1,
        },
        { merge: true }
      );
    } else {
      await setDoc(doc(db, "posts", id, "likes", currentUser.uid), {
        username: currentUser.username,
      });

      await setDoc(
        postLikes,
        {
          likesCount: currentLikes + 1,
        },
        { merge: true }
      );
    }
  };

  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);

  useEffect(
    () =>
      onSnapshot(
        query(
          collection(db, "posts", id, "comments"),
          orderBy("timestamp", "desc")
        ),
        (snapshot) => setComments(snapshot.docs)
      ),
    [db]
  );

  const sendComment = async (e) => {
    e.preventDefault();

    const commentToSend = comment;
    setComment("");

    const filter = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      temperature: 0,
      max_tokens: 1,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      messages: [
        {
          role: "system",
          content:
            'As a helpful and discerning bot, determine if this text is mean, derogatory, discriminatory, negative, or vulgar. For example, this would count "this is dumb, you should stop posting". Respond with only "yes" and "no".',
        },
        { role: "user", content: commentToSend },
      ],
    });

    const check =
      filter["data"]["choices"][0]["message"]["content"].toLowerCase();

    if (check === "no") {
      await addDoc(collection(db, "posts", id, "comments"), {
        comment: commentToSend,
        username: currentUser.username,
        userId: currentUser.uid,
        timestamp: serverTimestamp(),
      });


      const reference = doc(db, "posts", id);
      const post = await getDoc(reference);
      const numComments = post?.data()?.commentsCount || 0;

      await setDoc(
        reference,
        {
          commentsCount: numComments + 1,
        },
        { merge: true }
      );
    } else {
      const postStatsRef = doc(db, "stats", "meanCommentsCaught");
      const postStatsSnapshot = await getDoc(postStatsRef);
      const currentProtectedValue = postStatsSnapshot?.data()?.protected || 0;

      await setDoc(
        postStatsRef,
        {
          protected: currentProtectedValue + 1,
        },
        { merge: true }
      );
    }
  };

  const handleDelete = async (commentId) => {
    // commentId.preventDefault();
    await deleteDoc(doc(db, "posts", id, "comments", commentId));

    const reference = doc(db, "posts", id);
    const post = await getDoc(reference);
    const numComments = post?.data()?.commentsCount;

    await setDoc(
      reference,
      {
        commentsCount: numComments - 1,
      },
      { merge: true }
    )
  };

  const handleClick = () => {
    setShowDetails(!showDetails);
  };

  const handleLikedUsers = () => {
    setShowLiked(!showLiked);
  };

  return (
    <div className="post">
      {!isAnonymous && !isProfile && (
        <div className="post__header">
          <Link
            to={`/profiles/${userId}`}
            style={{ textDecoration: "none", color: "white" }}
          >
            <div className="post__headerAuthor">
              <Avatar
                style={{ marginRight: "10px", height: "60px", width: "60px" }}
                src={profileImage}
              ></Avatar>
              <div className="post__headerAuthor__name">{user}</div>

              {college && (
                <>
                  {
                    <span style={{ paddingRight: "15px", marginLeft: "13px" }}>
                      •
                    </span>
                  }
                </>
              )}
              {college && <span>{college}</span>}
            </div>
          </Link>
        </div>
      )}

      <div className="post__image" onClick={handleClick}>
        {showDetails ? (
          <>
            {isSocial && (
              <>
                <p>{advice}</p>
                {isAnonymous && <h4>Anonymous</h4>}
              </>
            )}
            {isActivity === true && (
              <>
                <h1>{activity}</h1>

                {significance && significance !== "" && (
                  <>
                    <h4>Significance</h4>
                    <p>{significance}</p>
                  </>
                )}

                {memory && memory !== "" && (
                  <>
                    <h4>Best Memory</h4>
                    <p>{memory}</p>
                  </>
                )}

                {advice && advice !== "" && (
                  <>
                    <h4>Advice</h4>
                    <p>{advice}</p>
                  </>
                )}
              </>
            )}

            {isAcademic === true && (
              <>
                {academicGoal && academicGoal !== "" && (
                  <>
                    <h4>Academic Goals</h4>
                    <p>{academicGoal}</p>
                  </>
                )}

                {ib && ib !== "" && (
                  <>
                    <h4>IB Diploma Advice</h4>
                    <p>{ib}</p>
                  </>
                )}

                {course && course !== "" && (
                  <>
                    <h4>Recommended Class</h4>
                    <p>{course}</p>
                  </>
                )}

                {teacher && teacher !== "" && (
                  <>
                    <h4>Teacher</h4>
                    <p>{teacher}</p>
                  </>
                )}

                {value && value !== "" && (
                  <>
                    <h4>What made the class worth it</h4>
                    <p>{value}</p>
                  </>
                )}

                {memory && memory !== "" && (
                  <>
                    <h4>Memorable Moment</h4>
                    <p>{memory}</p>
                  </>
                )}

                {relatedActs && relatedActs !== "" && (
                  <>
                    <h4>Related Activities</h4>
                    <p>{relatedActs}</p>
                  </>
                )}
              </>
            )}

            {isCollege === true && (
              <>
                {research && research !== "" && (
                  <>
                    <h4>How I researched colleges</h4>
                    <p>{research}</p>
                  </>
                )}

                {fairs && fairs !== "" && (
                  <>
                    <h4>Visiting Colleges & Fairs</h4>
                    <p>{fairs}</p>
                  </>
                )}

                {knownEarlier && knownEarlier !== "" && (
                  <>
                    <h4>Aspects I wished I knew earlier</h4>
                    <p>{knownEarlier}</p>
                  </>
                )}

                {selection && selection !== "" && (
                  <>
                    <h4>How I chose my college</h4>
                    <p>{selection}</p>
                  </>
                )}

                {regrets && regrets !== "" && (
                  <>
                    <h4>What I would do differently</h4>
                    <p>{regrets}</p>
                  </>
                )}

                {essayApproach && essayApproach !== "" && (
                  <>
                    <h4>How I approached the essays</h4>
                    <p>{essayApproach}</p>
                  </>
                )}

                {essayLink && essayLink !== "" && (
                  <>
                    <a href={essayLink} style={{ marginRight: "8px" }}>
                      College Essay
                    </a>
                  </>
                )}

                {resumeLink && resumeLink !== "" && (
                  <>
                    <a href={resumeLink}>Resume</a>
                  </>
                )}
              </>
            )}

            {isJob === true && (
              <>
                {employer && employer !== "" && (
                  <>
                    <h4>Workplace</h4>
                    <p>{employer}</p>
                  </>
                )}

                {purpose && purpose !== "" && (
                  <>
                    <h4>Motivation for Work</h4>
                    <p>{purpose}</p>
                  </>
                )}

                {advice && advice !== "" && (
                  <>
                    <h4>Experience and Recommendations</h4>
                    <p>{advice}</p>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <h1>{quote}</h1>
            <p style={{ textAlign: "right" }}>Tap to view response</p>
          </>
        )}
      </div>
      <div className="post__footer">
        <div className="post__footerIcons">
          <div className="post__iconsMain">
            {hasLiked ? (
              <FavoriteIcon onClick={likePost} className="postIcon" />
            ) : (
              <FavoriteBorderIcon onClick={likePost} className="postIcon" />
            )}
            <ChatBubbleOutlineIcon className="postIcon" />
          </div>
          {/* <div className="post__iconSave">
            <BookmarkBorderIcon className="postIcon" />
          </div> */}
        </div>
        <div onClick={handleLikedUsers} className="likedBy">
          {likes.length > 1 ? (
            <>Liked by {likes.length} people</>
          ) : likes.length === 1 ? (
            <>Liked by 1 person</>
          ) : (
            <>No likes yet</>
          )}

          {likes.length > 0 && showLiked && (
            <>
              <LikedBy postId={id} />
            </>
          )}
        </div>

        {comments.length > 0 && (
          <div
            style={{
              maxHeight: "7rem",
              overflowY: "scroll",
              scrollbarColor: "black",
              scrollbarWidth: "thin",
            }}
          >
            {comments.map((comment) => (
              <div key={comment.id} className="post__comment">
                <span
                  className="comment__left"
                  style={{ fontSize: "small", flex: 1 }}
                >
                  <span style={{ fontWeight: "700" }}>
                    {comment.data().username}
                  </span>{" "}
                  {comment.data().comment}
                  {comment.data().userId === currentUser.uid && (
                    <button
                      className="post__deleteComment"
                      onClick={() => handleDelete(comment.id)}
                    >
                      Delete
                    </button>
                  )}
                </span>

                <Moment
                  fromNow
                  style={{ fontSize: "0.75rem", paddingRight: "0.5rem" }}
                >
                  {comment.data().timestamp?.toDate()}
                </Moment>
              </div>
            ))}
          </div>
        )}
        {
          <form
            style={{
              display: "flex",
              style: "items-center",
              paddingTop: "0.5rem",
            }}
          >
            <input
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Add a comment..."
              className="comment__input"
            />
            <button
              type="submit"
              disabled={!comment.trim()}
              onClick={sendComment}
              className="comment__submit"
            >
              Submit
            </button>
          </form>
        }
      </div>
    </div>
  );
}

export default ActivityPost;
