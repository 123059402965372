import React from "react";
import "./Sidenav.css";
import HomeIcon from "@mui/icons-material/Home";

import SchoolIcon from '@mui/icons-material/School';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import ExploreIcon from "@mui/icons-material/Explore";
import { Link } from "react-router-dom";

function Sidenav() {

  return (
    <div className="sidenav">
        <div class="navbar-border"></div>


      <div className="sidenav__buttons">
        <Link to ="/" className="sidenav__button">
          <HomeIcon />
          <span>Home</span>
        </Link>
        <Link to="/social"className="sidenav__button">
          <FreeBreakfastIcon />
          <span>Social</span>
        </Link>
        <Link to="/academics"className="sidenav__button">
          <SchoolIcon />
          <span>Academics</span>
        </Link>
        <Link to="/activities" className="sidenav__button">
          <SportsFootballIcon />
          <span>Activities</span>
        </Link>
        <Link to="/jobs"className="sidenav__button">
          <ApartmentIcon />
          <span>Jobs & Internships</span>
        </Link>
        <Link to="/college" className="sidenav__button">
          <AccountBalanceIcon />
          <span>College</span>
        </Link>

        
      </div>
      <div className="sidenav__more">
        <Link className="sidenav__button">
          <ExploreIcon />
          <span>About</span>
        </Link>
      </div>
    </div>
  );
}



export default Sidenav;
