import React, { useState } from "react";
import "./Authenticate.css";
import Login from "./Login";
import Signup from "./Signup";

function Authenticate() {
  const [active, setActive] = useState("login");

  const handleChange = () => {
    setActive(active === "login" ? "signup" : "login");
  };

  return (
    <div className="authenticate">
      <div className="auth__left">
        <img
          src="https://lh3.googleusercontent.com/drive-viewer/AITFw-yzxJC_19DkKc7uWfSDw9ECAYOn6tputVsz080wlrpyBuAuI7CcW0dLGoSBXMAsg2v8YO7spl0K3DcyZEMFyuW73Y6slg=s1600"
          alt="Afterglow Screenshots"
        />
      </div>
      <div className="auth__right">
        {active === "login" ? <Login /> : <Signup />}

        <div className="auth__more">
          <span>
            {active === "login" ? (
              <>
                Don't have an account?{" "}
                <button onClick={handleChange}>Sign Up</button>
                <div style = {{marginTop: "5px"}}>
                Want a sneak peek?{" "}
                <a
                  href="https://andersonafterglow.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button>Tap Here</button>
                </a>
                </div>
              </>
            ) : (
              <>
                Have an account? <button onClick={handleChange}>Log in</button>
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Authenticate;
