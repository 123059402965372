import React from "react";
import "./Navbar.css";
import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "firebase/auth";
import { logoutUser } from "../features/userSlice";
import { auth } from "../firebase";
import { Link } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function Navbar() {
  const user = useSelector((state) => state.data.user.user);
  const dispatch = useDispatch();
  const handelLogout = () => {
    dispatch(logoutUser());
    signOut(auth);
  };
  return (
    <div className="navbar">
      <div className="navbar__left">
        <img
          className="navbar__logo"
          src="https://drive.google.com/uc?export=view&id=1P2Wijuw7umMRSAhwdivUSNDxacVLvlMW"
          alt="Afterlogw Logo"
        />
      </div>
      <div className="navbar__right" >
        
        <Link to="/profiles" className="navbar__button">
          <AccountCircleIcon style={{ height: "40px", width: "40px" }}/>
          <span>Profiles</span>
        </Link>

        <button onClick={handelLogout} style = {{marginLeft: "10px"}} className="navbar__button">
          <Avatar style={{ height: "35px", width: "35px" }}>
            {user.username ? user.username.charAt(0).toUpperCase() : ""}
          </Avatar>
          <span>{user.username}</span>
        </button>

      </div>
    </div>
  );
}

export default Navbar;
