import React, { useState, useEffect } from "react";
import Suggestions from "./Suggestions";
import "./Timeline.css";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  limit,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { db } from "../firebase";
import ActivityPost from "./Post/ActivityPost";

function Timeline({ postType }) {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let posts = [];
        let querySnapshot;

        if (postType !== "home") {
          querySnapshot = await getDocs(
            query(
              collection(db, "posts"),
              where(postType, "==", true),
              orderBy("rank", "desc")
            )
          );

          querySnapshot.docs.forEach((post) => {
            posts.push({ id: post.id, ...post.data() });
          });
        } else {
          const currentTime = Timestamp.now().toMillis();
          const millisecondsInADay = 24 * 60 * 60 * 1000;

          const reference = doc(db, "stats", "dailyPosts");
          const postArray = await getDoc(reference);
          const savedTime = postArray?.data()?.timestamp.toMillis();

          let oneDoc;

          if (currentTime > savedTime + millisecondsInADay) {
            let dailyPosts = []; //stores what will go into stats/dailyPosts

            for (let i = 0; i < 3; i++) {
              const newDocRef = doc(collection(db, "posts"));
              const key = newDocRef.id; //used to find a random post

              await deleteDoc(doc(db, "posts", key));

              oneDoc = await getDocs(
                query(
                  collection(db, "posts"),
                  where("__name__", ">=", key),
                  limit(1)
                )
              );

              if (oneDoc.empty) {
                oneDoc = await getDocs(
                  query(
                    collection(db, "posts"),
                    where("__name__", "<=", key),
                    limit(1)
                  )
                );
              }

              oneDoc.docs.forEach((doc) => {
                posts.push({ id: doc.id, ...doc.data() }); //adds the post id
                dailyPosts.push(doc.id); //for each random doc found, it adds it to the dailyPosts array
              });
            }

            await setDoc(doc(db, "stats", "dailyPosts"), {
              dailyPosts: dailyPosts, //sets the array of post ID's in firebase to the dailyPosts array created above
              timestamp: serverTimestamp(),
            });
          } else {
            const savedDocIds = postArray?.data()?.dailyPosts;

            for (let i = 0; i < 3; i++) {
              const oneDoc = await getDocs(
                query(
                  collection(db, "posts"),
                  where("__name__", "==", savedDocIds[i])
                )
              );

              oneDoc.docs.forEach((doc) => {
                posts.push({ id: doc.id, ...doc.data() });
              });
            }
          }
        }

        const promises = posts.map(async (post) => {
          if (post.firstName && post.lastName) {
            const firstName = post.firstName;
            const lastName = post.lastName;

            const userSnapshot = await getDocs(
              query(
                collection(db, "users"),
                where("firstName", "==", firstName),
                where("lastName", "==", lastName)
              )
            );

            return userSnapshot.docs.map((userDoc) => {
              const userData = { userId: userDoc.id, ...userDoc.data() };
              return { ...post, ...userData };
            });
          } else {
            return post;
          }
        });

        const results = await Promise.all(promises);

        // Flatten the array of arrays into a single array
        const mergedData = results.flat();

        if (postType !== "home") {
          const likesWeight = 0.4; // Adjust as needed
          const commentsWeight = 0.5; // Adjust as needed
          const rankWeight = 0.2; // Adjust as needed

          // Sort the posts based on the score calculated using likesCount, commentsCount, rank, and a random factor
          mergedData.sort(
            (a, b) =>
              (b?.likesCount || 0) * likesWeight +
              (b?.commentsCount || 0) * commentsWeight +
              ((b?.rankWeight || 0) * rankWeight + Math.random() * 6.2) -
              ((a?.likesCount || 0) * likesWeight +
                (a?.commentsCount || 0) * commentsWeight +
                ((a?.rankWeight || 0) * rankWeight + Math.random() * 6.2))
          );
        }

        setProfiles(mergedData);
      } catch (error) {
        // Handle any potential errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [postType]);

  return (
    <div className="timeline">
      <div className="timeline__left">
        <div className="timeline__posts">
        <div className="timeline__header">
        {postType === "home" && <h5>Posts of the Day</h5>}
        {postType === "isActivity" && <h5>Extracurricular Highlights</h5>}
        {postType === "isSocial" && <h5>Friendship & Growth</h5>}
        {postType === "isJob" && <h5>Employment & Internship Experiences</h5>}
        {postType === "isAcademic" && <h5>Academic Reflections</h5>}
        {postType === "isCollege" && <h5>College Application Insights</h5>}
        </div>
          {profiles.map((post) => (
            <ActivityPost
              key={post.id}
              isProfile={false}
              id={post.id}
              userId={post.userId}
              user={post.firstName + " " + post.lastName}
              postImage={post.address}
              profileImage={post.img}
              college={post.college}
              major={post.major}
              quote={post.quote}
              isSocial={post.isSocial}
              isAnonymous={post.isAnonymous}
              //for activity
              isActivity={post.isActivity}
              activity={post.activity}
              advice={post.advice}
              memory={post.memory}
              significance={post.significance}
              //for job
              isJob={post.isJob}
              employer={post.employer}
              purpose={post.purpose}
              //advice is also used

              //for academics
              isAcademic={post.isAcademic}
              academicGoal={post.academicGoal}
              ib={post.ib}
              course={post.course}
              teacher={post.teacher}
              value={post.value}
              relatedActs={post.relatedActs}
              //for college apps
              isCollege={post.isCollege}
              research={post.research}
              fairs={post.fairs}
              knownEarlier={post.knownEarlier}
              selection={post.selection}
              regrets={post.regrets}
              essayApproach={post.essayApproach}
              essayLink={post.essayLink}
              resumeLink={post.resumeLink}
              likes={0}
            />
          ))}
        </div>
      </div>
      <div className="timeline__right">
        <Suggestions />
      </div>
    </div>
  );
}

export default Timeline;
