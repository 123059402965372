import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { OpenAIApi, Configuration } from "openai";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "react-draft.firebaseapp.com",
  projectId: "react-draft",
  storageBucket: "react-draft.appspot.com",
  messagingSenderId: "855875325085",
  appId: "1:855875325085:web:aa0a706bae5c72d4321874",
  measurementId: "G-5MD3NWPQN3"
};

const config = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_KEY,
});

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
export const perf = getPerformance(app);

export const openai = new OpenAIApi(config);

