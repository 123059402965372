import "./App.css";
import Homepage from "./Homepage";
import Authenticate from "./authenticate/Authenticate";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { auth } from "./firebase";
import { loginUser, setLoading } from "./features/userSlice";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  activityInputs,
  collegeInputs,
  academicInputs,
  jobInputs,
  userInputs,
  socialInputs,
} from "./formSource";

import New from "./pages/New/New";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        dispatch(
          loginUser({
            uid: authUser.uid,
            username: auth.currentUser.displayName,
            email: authUser.email,
          })
        );
        dispatch(setLoading(false));
      } else {
        dispatch(setLoading(false));
        console.log("User is not logged in.");
      }
    });
  }, []);

  const user = useSelector((state) => state.data.user.user);
  const isLoading = useSelector((state) => state.data.user.isLoading);

  const randomDivToShow = () => {
    const divs = [
      <div className="loading__quote" >Fetching files from the asbestos closets...</div>,

    ];
  
    const randomIndex = Math.floor(Math.random() * divs.length);
    return divs[randomIndex];
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div className="app">
              {isLoading ? (
                <div className="loader-container">
                  <div className="loader"></div>
                  {randomDivToShow()}
                </div>
              ) : (
                <>
                  {user ? <Homepage timelineType={"home"} /> : <Authenticate />}
                </>
              )}
            </div>
          }
        />

        <Route
          path="/profiles"
          element={
            user ? <Homepage timelineType={"isProfiles"} /> : <Authenticate />
          }
        ></Route>

        <Route
          path="/profiles/:id"
          element={
            user ? <Homepage timelineType={"isProfile"} /> : <Authenticate />
          }
        />

        <Route
          path="social"
          element={
            user ? <Homepage timelineType={"isSocial"} /> : <Authenticate />
          }
        />

        <Route
          path="activities"
          element={
            user ? <Homepage timelineType={"isActivity"} /> : <Authenticate />
          }
        />
        <Route
          path="college"
          element={
            user ? <Homepage timelineType={"isCollege"} /> : <Authenticate />
          }
        />
        <Route
          path="academics"
          element={
            user ? <Homepage timelineType={"isAcademic"} /> : <Authenticate />
          }
        />
        <Route
          path="jobs"
          element={
            user ? <Homepage timelineType={"isJob"} /> : <Authenticate />
          }
        />

        <Route
          path="/new"
          element={<New inputs={userInputs} type={"users"} />}
          title="add new user"
        />
        <Route
          path="/new/activity"
          element={<New inputs={activityInputs} type={"isActivity"} />}
          title="add new activity advice"
        />
        <Route
          path="/new/academic"
          element={<New inputs={academicInputs} type={"isAcademic"} />}
          title="add new academic advice"
        />
        <Route
          path="/new/college"
          element={<New inputs={collegeInputs} type={"isCollege"} />}
          title="add new college advice"
        />
        <Route
          path="/new/social"
          element={<New inputs={socialInputs} type={"isSocial"} />}
          title="add new social advice"
        />
        <Route
          path="/new/job"
          element={<New inputs={jobInputs} type={"isJob"} />}
          title="add new job advice"
        />

        <Route path="*" element={user ? <Homepage /> : <Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
