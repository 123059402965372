import {
  signInWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
} from "firebase/auth";
import React, { useState } from "react";
import { auth } from "./../firebase";
import "./Login.css";

function Login() {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [reset, setReset] = useState(false);

  const [resetError, setResetError] = useState(false);
  const [sent, setSent] = useState(false);

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        // const user = userCredential.user;
      })
      .catch((error) => {
        setError(true);

        // ..
      });
  };

  const handleReset = () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSent(true);
      })
      .catch((error) => {
        setResetError(true);
      });
  };

  return (
    <div className="login">
      <img
        src="https://drive.google.com/uc?export=view&id=1P2Wijuw7umMRSAhwdivUSNDxacVLvlMW"
        alt="Afterglow Title"
      />

      {!reset ? (
        <>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="EMAIL"
          />
          <input
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="PASSWORD"
          />
          {error && <span style={{paddingBottom: "8px"}}>Wrong Email or Password</span>}
          <button onClick={handleLogin}>Log In</button>
          {error && (
            <span
              style={{ cursor: "pointer", color: "#0095f6", paddingTop: "8px" }}
              onClick={() => {
                setReset(true);
                setPassword("");
              }}
            >
              Forgot Password?
            </span>
          )}
        </>
      ) : (
        <>
          <p style={{ color: "white" }}>
            Enter your email and we'll send you a link to get back into your
            account.
          </p>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            placeholder="EMAIL"
            value={email}
            style={{ color: "white" }}
          />
          <button onClick={handleReset}>Send Email</button>
          {resetError && <span>Email could not be found</span>}
          {sent && <span>Successfully sent!</span>}
        </>
      )}
    </div>
  );
}

export default Login;
