import { Avatar } from "@mui/material";
import React from "react";
import "./Suggestions.css";

function Suggestions() {
  return (
    <div className="suggestions">
      <div className="suggestions__title">Placeholder Column</div>
      
  
    </div>
  );
}

export default Suggestions;
