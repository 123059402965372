import React, { useState } from 'react'
import {
    collection,
    query,
    where,
    getDocs
  } from "firebase/firestore";
  import { useEffect } from 'react';
  import { db } from "../../firebase";
  import "./LikedBy.css";

function LikedBy({postId}) {

    const [likedBy, setLikedBy] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const querySnapshot = await getDocs(
              query(collection(db, "posts", postId, "likes"))
            );
      
            const usernames = querySnapshot.docs.map((doc) => {
                const postData = doc.data();
                return postData.username;
              });

              setLikedBy(usernames);
          } catch (error) {
            // Handle any potential errors
            console.error("Error fetching data:", error);
          }
        };
      
        fetchData();
      }, []);


  return (
    <div className="post__likedBy">
      {likedBy.map((username, index) => (
        <div key={index}>{username}</div>
      ))}
    </div>
  );
}

export default LikedBy