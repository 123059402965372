import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import "./Profiles.css";
import Suggestions from "../timeline/Suggestions";
import { Avatar } from "@mui/material";
import ActivityPost from "../timeline/Post/ActivityPost";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import { Instagram } from "@mui/icons-material";

function Profile() {
  const { id } = useParams();

  const [profile, setProfile] = useState([]);
  const [posts, setPosts] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const handleClick = () => {
    setShowDetails(!showDetails);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);
        setProfile(docSnap.data());

        const firstName = docSnap.data().firstName;
        const lastName = docSnap.data().lastName;

        let querySnapshot;

        querySnapshot = await getDocs(
          query(
            collection(db, "posts"),
            where("firstName", "==", firstName),
            where("lastName", "==", lastName)
          )
        );

        const promises = await querySnapshot.docs.map(async (doc) => {
          return { id: doc.id, ...doc.data() };
        });

        const results = await Promise.all(promises);
        setPosts(results);

        // } else {
        //   console.log("last and first name undef");
        // }
      } catch (error) {
        // Handle any potential errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="profiles">
      <div className="profiles__left">
        <div className="profile__cover">
          <div style={{ width: "250px", height: "250px" }}>
            <Avatar
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              src={profile.img}
            />
          </div>
          <h1>
            <span>{`${profile.firstName} ${profile.lastName}`}</span>
            <span>
              {profile.isIb && (
                <>
                  <ElectricBoltIcon style={{ color: "rgb(0, 50,255)" }} />
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "grey",
                    }}
                  >
                    (IB Diploma)
                  </span>
                </>
              )}
            </span>
          </h1>
        </div>

        {((profile.instagram && profile.instagram !== null) ||
          (profile.email && profile.email !== null)) && (
          <div className="profile__cover">
            {profile.instagram && profile.instagram !== null && (
              <h3 style={{ display: "flex", alignItems: "center" }}>
                <Instagram style={{ paddingRight: "5px" }} />
                {`@${profile.instagram}`}
              </h3>
            )}

            {profile.email && profile.email !== null && (
              <h3>{`${profile.email}`}</h3>
            )}
          </div>
        )}

        <div className="profile__gallery">
          {posts.map((post) => (
            <ActivityPost
              isProfile={true}
              id={post.id}
              key={post.id}
              user={post.firstName + " " + post.lastName}
              postImage={post.address}
              profileImage={post.img}
              college={post.college}
              major={post.major}
              quote={post.quote}
              //for activity
              isActivity={post.isActivity}
              activity={post.activity}
              advice={post.advice}
              memory={post.memory}
              significance={post.significance}
              //for job
              isJob={post.isJob}
              employer={post.employer}
              purpose={post.purpose}
              //advice is also used

              //for academics
              isAcademic={post.isAcademic}
              academicGoal={post.academicGoal}
              ib={post.ib}
              course={post.course}
              teacher={post.teacher}
              value={post.value}
              relatedActs={post.relatedActs}
              //for college apps
              isCollege={post.isCollege}
              research={post.research}
              fairs={post.fairs}
              knownEarlier={post.knownEarlier}
              selection={post.selection}
              regrets={post.regrets}
              essayApproach={post.essayApproach}
              essayLink={post.essayLink}
              resumeLink={post.resumeLink}
              likes={0}
            />
          ))}
        </div>

        {profile.college && profile.college !== null && (
          <div className="profile__cover">
            <h2>{`${profile.college}`}</h2>
            <h2>{`${profile.major}`}</h2>
          </div>
        )}

        <div className="profile__cover">
          {profile.elementaryS && profile.elementaryS !== null && (
            <>
              <div style={{
                marginTop: "5px",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "grey",
                    }}> Elementary School</div>
              <h2>{`${profile.elementaryS}`}</h2>
            </>
          )}
          {profile.middleS && profile.middleS !== null && (
            <>
              <div style={{
                marginTop: "5px",
                      fontWeight: "400",
                      fontSize: "12px",
                      color: "grey",
                    }}>Middle School</div>
              <h2>{`${profile.middleS}`}</h2>
            </>
          )}
        </div>
      </div>

      
      <div className="profiles__right">
        <Suggestions />
      </div>
    </div>
  );
}

export default Profile;
