import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import "./Profiles.css";
import { db } from "../firebase";
import Suggestions from "../timeline/Suggestions";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import { VerticalAlignCenter } from "@mui/icons-material";

function Profiles() {
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let querySnapshot;

        querySnapshot = await getDocs(
          query(collection(db, "users"), orderBy("firstName", "asc"))
        );

        const promises = await querySnapshot.docs.map(async (doc) => {
          return { id: doc.id, ...doc.data() };
        });

        const results = await Promise.all(promises);

        await setProfiles(results);
      } catch (error) {
        // Handle any potential errors
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="profiles">
      <div className="profiles__left">
        {profiles.map((post) => (
          <Link to={`/profiles/${post.id}`} style={{ textDecoration: "none" }}>
            <div className="profile">
              <div className="profile__left">
                <Avatar
                  style={{
                    marginRight: "10px",
                    height: "75px",
                    width: "75px",
                  }}
                  src={post.img}
                ></Avatar>
              </div>
              <div className="profile__right">
                <div style={{ fontWeight: "700", display: "flex", alignItems: "center"}}>
                  {post.firstName + " " + post.lastName}
                  {post.isIb && (
                    <span><ElectricBoltIcon style={{ color: "rgb(100, 150,255)"}}/></span>
                  )}
                </div>
                {post.college && (
                  <div>{post.college + " • " + post.major} </div>
                )}
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="profiles__right">
        <Suggestions />
      </div>
    </div>
  );
}

export default Profiles;
