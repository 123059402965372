export const userInputs = [
  {
    id: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "john",
  },

  {
    id: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "doe",
  },

  {
    id: "elementaryS",
    label: "Elementary School",
    type: "text",
    placeholder: "Doss",
  },

  {
    id: "middleS",
    label: "Middle School",
    type: "text",
    placeholder: "Murchison",
    value: "Murchison",
  },
  {
    id: "college",
    label: "College",
    type: "text",
    placeholder: "ACC",
  },
  {
    id: "major",
    label: "What you will study",
    type: "text",
    placeholder: "English",
  },

  {
    id: "email",
    label: "Email",
    type: "mail",
    placeholder: "john_doe@gmail.com",
  },

  {
    id: "instagram",
    label: "Instagram",
    type: "text",
    placeholder: "",
  },

];

export const activityInputs = [
  {
    id: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "john",
  },

  {
    id: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "doe",
  },
  {
    id: "activity",
    label: "Activity",
    type: "text",
    placeholder: "Speech & Debate",
  },
  {
    id: "significance",
    label: "Significance",
    type: "text",
    placeholder: "What made it meaningful?",
  },
  {
    id: "memory",
    label: "Memory",
    type: "text",
    placeholder: "Favorite Memory?",
  },
  {
    id: "advice",
    label: "Advice",
    type: "text",
    placeholder: "What advice would you give?",
  },
];

export const collegeInputs = [
  {
    id: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "john",
  },

  {
    id: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "doe",
  },
  {
    id: "research",
    label: "How did you research colleges?",
    type: "text",
  },
  {
    id: "fairs",
    label:
      "Did you visit any colleges or attend college fairs? Was the experience helpful?",
    type: "text",
  },

  {
    id: "knownEarlier",
    label:
      "Are there aspects of the college process you wish you had known earlier?",
    type: "text",
  },

  {
    id: "selection",
    label: "How did you end up selecting the college you chose?",
    type: "text",
  },

  {
    id: "regrets",
    label:
      "What would you have done differently during high school and/or the application process if given a second chance?",
    type: "text",
  },

  {
    id: "essayApproach",
    label:
      "How did you approach the college essays and/or the personal statement?",
    type: "text",
  },

  {
    id: "essayLink",
    label: "Link to Essay",
    type: "text",
  },

  {
    id: "resumeLink",
    label: "Link to Resume",
    type: "text",
  },
];

export const academicInputs = [
  {
    id: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "john",
  },

  {
    id: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "doe",
  },
  {
    id: "academicGoal",
    label: "What were your academic goals?",
    type: "text",
  },
  {
    id: "ib",
    label:
      "What was your experience in IB like? Who would you recommend it to?",
    type: "text",
  },

  {
    id: "course",
    label: "what class did you enjoy?",
    type: "text",
  },

  {
    id: "teacher",
    label: "Which teacher taught it?",
    type: "text",
  },

  {
    id: "value",
    label: "What made the class meaningful?",
    type: "text",
  },

  {
    id: "memory",
    label: "Memorable Moments?",
    type: "text",
  },

  {
    id: "relatedActs",
    label: "Any activities you did that related to the class?",
    type: "text",
  },
];

export const jobInputs = [
  {
    id: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "john",
  },

  {
    id: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "doe",
  },

  {
    id: "employer",
    label: "Where did you work?",
    type: "text",
  },

  {
    id: "purpose",
    label: "Why did you work?",
    type: "text",
  },

  {
    id: "advice",
    label: "What work advice would you give?",
    type: "text",
  },
];

export const socialInputs = [
  {
    id: "firstName",
    label: "firstName",
    type: "text"
  },
  {
    id: "lastName",
    label: "lastName",
    type: "text"
  },

  {
    id: "advice",
    label: "what advice would you give?",
    type: "text"
  }
];
