import React from "react";
import "./Homepage.css";
import Sidenav from "./navigation/Sidenav";
import Timeline from "./timeline/Timeline";
import Profiles from "./profiles/Profiles";
import Profile from "./profiles/Profile";
import Navbar from "./navigation/Navbar";

function Homepage(props) {
  const { timelineType } = props;

  return (
    <div>
      <><Navbar/></>
      <div className="homepage">
        <div>
          {/* <Navbar/> */}
          {/* {navbar on the top with the logo on the left and profiles and username on the right} */}
        </div>
        <div className="homepage__navWraper">
          <Sidenav />
        </div>
        <div className="homepage__timeline">
          {timelineType === "isProfiles" ? (
            <Profiles />
          ) : timelineType === "isProfile" ? (
            <Profile />
          ) : (
            <Timeline postType={timelineType} />
          )}
          <div class="navbar_border"></div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
