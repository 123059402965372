import React, { useState } from "react";
import "./Signup.css";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { auth } from "../firebase";
import { loginUser, setLoading } from "../features/userSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

function Signup() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const handleSignUp = (event) => {
    event.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then((authUser) => {
          const user = authUser.user;
          return updateProfile(user, {
            firstName: firstName.toLowerCase(),
            lastName: lastName.toLowerCase(),
            displayName: firstName + " " + lastName.charAt(0),
          });
        })
        .then(() => {
          // User profile update is completed
          dispatch(
            loginUser({
              uid: auth.currentUser.uid,
              username: auth.currentUser.displayName,
              email: auth.currentUser.email,
            })
          );
          dispatch(setLoading(false));
        })
        .catch((err) => {
          alert(err);
        });
  };

  return (
    <div className="signup">
      <img
        src="https://drive.google.com/uc?export=view&id=1P2Wijuw7umMRSAhwdivUSNDxacVLvlMW"
        alt="Afterglow Logo"
      />
     
      <input
        onChange={(e) => setEmail(e.target.value)}
        type="email"
        placeholder="EMAIL"
        value={email}
      />

      <input
        onChange={(e) => setFirstName(e.target.value)}
        type="text"
        placeholder="FIRST NAME"
        value={firstName}
      />

      <input
        onChange={(e) => setLastName(e.target.value)}
        type="text"
        placeholder="LAST NAME"
        value={lastName}
      />

      <input
        onChange={(e) => setPassword(e.target.value)}
        type="password"
        placeholder="PASSWORD"
        value={password}
      />
      <button onClick={handleSignUp}>Sign up</button>
    </div>
  );
}

export default Signup;
